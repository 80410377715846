import React, { useEffect, useState } from 'react';
import { object } from 'prop-types';
import {
  HomeSlider,
  LandingArtistsBlock,
  LandingMusicBlock,
  LandingPartnersBlock,
  LandingProjectsBlock,
  Layout,
  SEO
} from '../components';
import { Helmet } from 'react-helmet';
import LandingClientsRoom from '../components/complex/LandingClientsRoom';

const IndexPage = ({ pageContext: { home_data }, ...props }) => {
  const [artists, setArtists] = useState(null);
  const [projects, setProjects] = useState(null);
  const [pageData, setPageData] = useState(null);

  const home_meta_data = home_data?.data;

  useEffect(() => {
    fetch(`${process.env.API_URL}/api/artists?featured=1`)
      .then((response) => response.json())
      .then((res) => {
        const data = res?.data || res;
        setArtists(data);
      });

    fetch(`${process.env.API_URL}/api/projects?featured=1`)
      .then((response) => response.json())
      .then((res) => {
        const data = res?.data || res;
        console.log(data);
        setProjects(data);
      });

    fetch(`${process.env.API_URL}/api/clients`)
      .then((response) => response.json())
      .then((res) => {
        const data = res.data || res;
        setPageData(data);
      });
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(props.location.search);
    const myParam = urlParams.get('section');

    const elemId = myParam?.replaceAll('/', '');
    const elem =
      document.getElementById(elemId) !== null &&
      document.getElementById(elemId);

    setTimeout(() => {
      window.scrollTo({
        top: elem.offsetTop,
        behavior: 'smooth',
      });
    }, 2000);
  }, [props.location.search]);

  return (
    <>
      <Layout>
        <SEO
          title={
            home_meta_data?.title || 'MusicRoom I Great concerts to be measured'
          }
          showDefaultTitle={false}
          description={
            home_meta_data?.description ||
            'MusicRoom coordinates and provides artists for your concerts'
          }
          image={home_meta_data?.image}
          keywords={home_meta_data?.keywords}
        />
        <HomeSlider />
        <LandingMusicBlock data={pageData?.music_room} />

        <LandingArtistsBlock
          data={pageData?.artist_room}
          artists={artists !== null && artists}
        />

        <LandingProjectsBlock
          data={pageData?.project_room}
          projects={projects !== null && projects}
        />

        <LandingClientsRoom data={pageData?.client_room} />

        {/* <LandingPartnersBlock data={pageData?.partner_room}/> */}
      </Layout>
    </>
  );
};

IndexPage.propTypes = {
  pageContext: object.isRequired,
};

export default IndexPage;
