import React from 'react';
import './landing-clients-room.scss';
import SwiperCore, {Navigation,A11y, Autoplay, EffectFade, Pagination} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/components/navigation/navigation.scss';

SwiperCore.use([Navigation,Pagination, A11y, EffectFade, Autoplay]);
const LandingClientsRoom = ({data}) => {

    const roomData = [];
    const count_of_item = 6;

    for (let i = 0; i < data?.items?.length/count_of_item; i++) {
        const num = i*count_of_item;
        roomData.push(data?.items?.slice(num, num+count_of_item));
    }

    return (
        <div className="landing__clientsRoom" id="clients">
            <div className="block">
                <h2 className="title">CLIENTS</h2>
                <h3 className="subtitle">ROOM</h3>
                <p className="paragraph" dangerouslySetInnerHTML={{__html: data?.text}}/>
            </div>
            <div className="landing__clientsRoom__slider">
                <Swiper
                    speed={500}
                    slidesPerView={1}
                    navigation
                    pagination={{
                        clickable: true,
                        renderBullet: function (index, className) {
                            return `<span class="my-bullets ${className}"><span class="my-bullets-btn"></span></span>`;
                        },
                    }}
                    direction="vertical"
                    breakpoints={{
                        480: {
                            direction: 'vertical',
                        },
                        0: {
                            direction: 'horizontal',
                        },
                    }}
                    // spaceBetween="10"
                >
                    {roomData?.map((sliderItem, i) =>
                        <SwiperSlide key={i}>
                            <ul>
                                {sliderItem.map((el, idx) => {
                                    return (
                                        <li key={idx}>
                                            <span dangerouslySetInnerHTML={{__html: el}}/>
                                        </li>
                                    )
                                })}
                            </ul>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </div>
    )
}
export default LandingClientsRoom
